import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

const LazyReservationsPage = React.lazy(
  () => import(/* webpackChunkName: "reservations-page" */ './Reservations'),
);

export const Reservations = (
  props: React.ComponentProps<typeof LazyReservationsPage>,
) => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyReservationsPage />
    </Suspense>
  );
};
