import React, { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';
import { useCustomInclusionsFeatureToggle } from 'src/hooks/useCustomInclusionsFeatureToggle';
import { usePropertyIdAlways } from 'src/hooks/usePropertyId';

const LazyInclusionsOverview = React.lazy(
  () =>
    import(
      /* webpackChunkName: "inclusions-overview" */ './components/InclusionsOverview'
    ),
);

export const Inclusions = (
  props: React.ComponentProps<typeof LazyInclusionsOverview>,
) => {
  const propertyId = usePropertyIdAlways();
  const [enableInclusions, featuresLoading] =
    useCustomInclusionsFeatureToggle();

  if (featuresLoading) {
    return <GenericLoadingIndicator />;
  }

  return enableInclusions ? (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyInclusionsOverview {...props} />
    </Suspense>
  ) : (
    <Redirect to={`/properties/${propertyId}`} />
  );
};
