import { useFeatureFlag } from 'src/lib/featureFlagsOptimizley';
import useCurrentUser from './useCurrentUser';

export function useCustomInclusionsFeatureToggle(): [boolean, boolean] {
  const isStaff = useCurrentUser().isStaff;

  const [featureEnabled, loaded] = useFeatureFlag(
    'supplier-extranet-custom-inclusions',
  );

  return [loaded && featureEnabled && isStaff, !loaded];
}
