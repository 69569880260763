import React from 'react';
import { HppRedirect } from '../../components/HppRedirect';
import { UseProperty_PropertyFragment } from '../useProperty/useProperty_Property';
import { useReservationsFeatureToggle } from '../useReservationsFeatureToggle';
import { useRouteMatch } from 'react-router-dom';
import useProperty from '../useProperty';

type HppRedirectProps = React.ComponentProps<typeof HppRedirect>;
type Redirect = {
  from: string;
  to: string;
  when?: Partial<UseProperty_PropertyFragment> & {
    displayReservations?: boolean;
  };
  props?: Omit<HppRedirectProps, 'from' | 'to'>;
};

const HPP_REDIRECTS: Redirect[] = [
  {
    from: '/admin',
    to: '/',
  },
  {
    from: '/extranet/supplier_agreement/standard',
    to: '/supplier_agreement/standard',
    props: {
      asset: true,
    },
  },
  {
    from: '/properties/:propertyId/room-types',
    to: '/properties/:propertyId/room_types',
  },
  {
    from: '/properties/:propertyId/reservations',
    to: '/properties/:propertyId/reservations',
    when: {
      displayReservations: false,
    },
  },
  {
    from: '/properties/:propertyId/trails',
    to: '/properties/:propertyId/trails',
  },
  {
    from: '/properties/:propertyId/user',
    to: '/properties/:propertyId/user',
  },
  {
    from: '/properties/:propertyId/rates-and-inventory/:roomTypeId/bulk-update',
    to: '/bulk_inventories/:roomTypeId/edit',
    when: {
      ratesServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/rates-and-inventory/:roomTypeId',
    to: '/inventories/:roomTypeId',
    when: {
      ratesServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/details/policies',
    to: '/properties/:propertyId/edit#policies',
  },
  {
    from: '/properties/:propertyId/details/commercial-terms',
    to: '/properties/:propertyId/edit#commercial_terms',
  },
  {
    from: '/properties/:propertyId/details/images',
    to: '/properties/:propertyId/edit#images',
  },
  {
    from: '/properties/:propertyId/details/other',
    to: '/properties/:propertyId/edit',
  },
  {
    from: '/properties/:propertyId/rate-plans/overview',
    to: '/properties/:propertyId/offers',
    when: {
      ratePlansServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/rate-plans/create',
    to: '/properties/:propertyId/offers/new',
    when: {
      ratePlansServiceEnabled: false,
    },
  },
  {
    from: '/properties/:propertyId/rate-plans/:ratePlanId',
    to: '/inventories/:propertyId',
    when: {
      ratePlansServiceEnabled: false,
    },
  },
];

export function useLegacyRedirects() {
  const property = useProperty();
  const matchRoute = useRouteMatch;
  const [displayReservations] = useReservationsFeatureToggle();
  let redirectProps = null;
  for (let i = 0; i < HPP_REDIRECTS.length; i++) {
    const config = HPP_REDIRECTS[i];
    const match = matchRoute(config.from);

    if (redirectProps || !match) {
      continue;
    }
    let conditions = true;
    if (property && match && config.when) {
      conditions = !Object.entries(config.when).some(([key, value]) => {
        if (key === 'displayReservations') {
          return displayReservations !== value;
        }

        return property[key as keyof UseProperty_PropertyFragment] !== value;
      });
    }
    if (conditions) {
      redirectProps = { from: config.from, to: config.to, ...config.props };
      break;
    }
  }

  if (redirectProps) {
    return React.createElement(HppRedirect, redirectProps);
  }
}
